import React from 'react';
import styled from 'styled-components';
import SEO from '../../components/seo';
import Layout from '../../components/Layout';

const Title = styled.h1`
  font-size: 40px;
  text-align: center;
`;

const Image = styled.img`
  max-height: 400px;
  display: block;
`;

const Copyright = styled.span`
  text-align: right;
  display: block;
  font-size: 13px;
  color: white;
  margin: 12px 0 32px;
`;

const Wrapper = styled.div`
  max-width: 720px;
  padding: 0 22px;
`;

const Subtitle = styled.h2`
  display: block;
  color: white;
  font-size: 30px;
  margin-top: 32px;
`;

const P = styled.p`
  color: white;
  font-size: 18px;
`;

function WineFestival() {
  return (
    <>
      <SEO title="Kom til Helsingør vinfestival d. 1 oktober" description="" />
      <Layout>
        <Wrapper className="event">
          <Title className="title">
            Oplev Helsingør vinfestival d. 1 oktober
          </Title>
          <Image
            className="event__image"
            src="https://vinfestival.dk/media/9550/helsingoervinfest_plakat22FINAL_500x700.jpg"
            alt="Vinfestival Helsingør"
          />
          <Copyright>Billede fra https://vinfestival.dk/</Copyright>
          <Subtitle>
            Tag til Helsingør og oplev et kæmpe udvalg af de lækreste vine
          </Subtitle>
          <P className="event__info_explore">
            Vinfestival. Det lyder jo lidt som paradis. Og det bedste ved det
            hele? Den er god nok!
            <br />
            <br />
            D. 1 oktober 2022 holdes der atter vinfestival også kendt som
            Helsingør vinfestival - hvilket indebærer hvor festivalen finder
            sted.
            <br />
            <br />
            Er du vinentusiast eller kan du bare godt lide en god stemning, er
            det bestemt et sted at tage hen. Samtidig finder du måske vinen, du
            slet ikke kendte til endnu?
          </P>
          <a
            style={{ maxWidth: 220 }}
            className="btn event__link info__buyticket-link onsale"
            href="https://www.ticketmaster.dk/event/helsingor-vinfestival-2022-flight-1-kl-1100-1330--billetter/516229?clickId=27zUfNVB8xyIUnxSIMxN7w-dUkDyNWz%3APUZK0A0&irgwc=1&utm_source=zooticket&utm_medium=affiliate&utm_campaign=1722511&utm_content=1049986_7521"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Køb billet her</span>
          </a>
        </Wrapper>
      </Layout>
    </>
  );
}

export default WineFestival;
